import React from 'react';
import { PageProps } from "gatsby";
import Layout from '../../../components/layout/layout';

const previewImage = {imageFile: 'home.png', imageWidth: 1200, imageHeight: 630, imageDescription: 'Screenshot of the website'};

export default function PageContent(props: PageProps) {
  let language = 'de';
  return (
    <Layout language={language} title="About me" description="Some more things about myself" path={props.path} previewimage={previewImage} hasLanguageCounterpart={true}>
      <h1>Impressum</h1>
      <p>
        Manuel Feller<br />
        Gartenfeldstraße 22<br />
        55218 Ingelheim<br />
        Germany<br />
        website@manuel-feller.net<br />
      </p>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationsmaterialien und Werbung wird hiermit ausdrücklich widersprochen.
        Ich behalte mir ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails oder Werbeanrufe, vor.
      </p>
      <h3>Datenschutz</h3>
      <p>
        Die Nutzung dieser Webseite ist ohne Angabe personenbezogener Daten möglich.
        Außerdem werden weder Log-Daten noch Tracking-Informationen gesammelt.
      </p>
      <h3>Cookies</h3>
      <p>
        Auch wenn Cookies durchaus lecker sind gibt es aktuell keinen Bedarf dafür.
        Aus diesem Grund muss auch keine Zustimmung gegeben werden....
      </p>
    </Layout>
  );
}

